<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { visited } from "../src/API/axios"
export default {
  name: 'App',
  created() {
    visited().then(() => { })
  },
}
</script>

<style lang="less">
@import 'style/basic.less';

#app {
  background-color: rgb(240, 240, 240);
  // background: url(../images/bj2.png);
  // background-color: rgba(87, 104, 235, 1);
		background-size: cover;
		    width: 100%;
		    height: 100%;
		    min-height: 100vh;

  .flexcenter {
    display: flex;
    justify-content: center;
  }

  .ch {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-family: 'SourceHanSansCN-Bold';
  }

  .colorbl {
    color: black;
  }

  .blu {
    color: rgba(19, 90, 173, 1);
  }
}
</style>
