<template>
    <div style="height: 100%;">
        <div>
            <img src="../assets/gywm.png" :style="screens > 1030 ? '' : 'height: 15rem;'"
                style="width: 100%;height: 30rem;">
        </div>
        <div class="flexcenter">
            <div class="bottom">
                <div class="bottomtitle" :style="screens > 1030 ? '' : 'font-size: 2rem;'">— —公司历史— —</div>
                <div class="flexcenter">
                    <div style="width: 80%;padding: 1rem 1rem 0 1rem;" :style="screens > 1030 ? '' : 'width: 90%;'">
                        <div class="flexcenter">
                            <div>
                                <el-row
                                    style="margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
                                    :gutter="40">
                                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                        <img src="../assets/qy.png" style="width: 100%;height: 20rem;"
                                            :style="screens > 1030 ? '' : 'height: 15rem;'">
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                        <div class="bottombox">
                                            <div class="bottomboxfont" :style="screens > 1030 ? '' : 'font-size: 1rem;'">
                                                &nbsp &nbsp &nbsp 中野战狼（大连）科技有限公司于2014年7月21日注册成立，注册资金2800万。
                                            </div>
                                            <div class="bottomboxfont" style="margin-top: 2rem;"
                                                :style="screens > 1030 ? '' : 'font-size: 1rem;'">
                                                主要经营产品为五金机电劳保护具、金属结构制造、新材料研发，等建筑材料…
                                            </div>
                                            <div style="float: right;margin-top: 3rem;">
                                                <el-button type="primary" @click="xiangqing">查看详细</el-button>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flexcenter">
                    <div style="width: 100%;">
                        <div
                            style="margin-top: 2rem;padding-top: 1rem; background: url(https://zl-925896390-1317109654.cos.ap-nanjing.myqcloud.com/2023/08/14/a079add740e44b9eb83c2c0e9b902574.jpg) no-repeat;border-radius: 34px;background-size: 100%;">
                            <div class="bottomtitle" style="color: white;"
                                :style="screens > 1030 ? '' : 'font-size: 2rem;'">—
                                —领导团队— —</div>
                            <el-row
                                style="display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;">
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <div class="box">
                                        <img src="../assets/team3.png" style="width: 20%;height: 100%;">
                                        <div style="position: absolute;top:10%;left:23%">
                                            <h2 style="margin-bottom: 55px;">姓名：拖晓航</h2>
                                            <h3 style="margin-bottom: 55px;">学历：博士</h3>
                                            <h3>专业：聚合物基复合材料，3D 打印，图像处理，品质管理</h3>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <div class="box">
                                        <img src="../assets/team1.png" style="width: 20%;height: 100%;">
                                        <div style="position: absolute;top:10%;left:23%">
                                            <h2 style="margin-bottom: 55px;">姓名：陈必承</h2>
                                            <h3 style="margin-bottom: 55px;">学历：研究生</h3>
                                            <h3>专业：3D打印复合材料的制备及应用</h3>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="flexcenter">
                    <div style="width: 80%;padding: 1rem 1rem 0 1rem;margin-bottom: 1rem;">
                        <div class="flexcenter">
                            <div>
                                <el-row
                                    style="margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
                                    :gutter="40">
                                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                        <img src="../assets/qyzz.png" style="width: 100%;height: 20rem;"
                                            :style="screens > 1030 ? '' : 'height: 10rem;'">
                                        <div class="imgbox">
                                            <div>企业资质</div>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                        <img src="../assets/fzfx.png" style="width: 100%;height: 20rem;"
                                            :style="screens > 1030 ? '' : 'height: 10rem;'">
                                        <div class="imgbox">企业文化</div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['screens'],
    data() {
        return {
            show: !false,
        }
    },
    methods: {
        infoWindowClose() {
            this.show = false
        },
        infoWindowOpen() {
            this.show = true
        },
        xiangqing() {
            this.$router.push('/detail?name=公司历史')
        }
    },
}
</script>

<style scoped>
.bottom {
    background: rgba(224, 224, 224, 1);
    width: 80%;
}

.bottomtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-top: 2rem;
}

.bottombox {
    border-radius: 2rem;
    background: rgba(204, 204, 204, 1);
    width: 100%;
    height: 100%;
    padding: 3rem;
}

.bottomboxfont {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.box {
    background: rgba(204, 204, 204, 0.3);
    margin: 3rem;
    height: 15rem;
    position: relative;
    /* mix-blend-mode: overlay; */
}

.imgbox {
    background: rgba(115, 115, 115, 0.5);
    height: 3rem;
    font-size: 2rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    transform: translate(0, -3rem)
}
</style>