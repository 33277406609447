<template>
  <div>
 <div>
      <el-carousel :height="screens >= 1030 ? '40rem' : '25rem'" width="100vw" :interval="5000" arrow="always">
        <el-carousel-item v-for="item in luobotuing" :key="item.id">
          <img :src="item.homePage" alt="" style="width: 100%;height: 100%;">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="words" style="overflow: hidden;">
      <el-row style="display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
        :gutter="40">
        <el-col v-if="arrdata.length >= 1" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div style="display: flex;">
            <i class="el-icon-message-solid"
              style="line-height: 3rem;font-size: 1rem;margin-right: 2rem;color: rgba(6, 69, 150, 1);"></i>
            <div style="width: 100%;overflow: hidden;">
              <div class="wordsfonts"><span class="wordsfont">{{ arrdata[0].createTime + " " }}{{ arrdata[0].title }}
                </span></div>
            </div>
          </div>
        </el-col>
        <el-col v-if="arrdata.length >= 2" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div style="display: flex;justify-content: space-between;">
            <div style="width: 100%;overflow: hidden;">
              <div class="wordsfonts"><span class="wordsfont">{{ arrdata[1].createTime + " " }}{{ arrdata[1].title }}
                </span></div>
            </div>
            <el-button type="primary" round style="margin-right: 2rem;margin-left: 1rem;"
              :style="screens > 1030 ? '' : 'margin-right: 0.5rem;'" @click="dynamic">查看更多</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="synopsis">
        <div class="synopsis-title">公司简介</div>
        <div class="synopsis-content">
            <div style="margin-right: 100px;width: 950px;">
                <img src="../assets/qytp.png">
            </div>
            <div>
                <div class="synopsis-text">
                   <div>&nbsp;</div>
                   <div style="margin: 30px 0;">
                    &nbsp;&nbsp;&nbsp;中野战狼（大连）科技有限公司于2014年7月21日注册成立，注册资金2800万。
                   </div>
                   <div>
                    主要经营产品为五金机电劳保护具、金属结构制造、新材料研发，等建筑材料…
                   </div>
                   <button @click="$router.push('/detail?name=公司历史')">查看详情</button>
                </div>
            </div>
        </div>
    </div>
    <div class="flexcenter">
      <div class="title">新闻动态</div>
    </div>
    <div class="flexcenter">
      <div style="width: 100%;padding: 1rem;">
        <div class="flexcenter">
          <div>
            <el-button type="primary" @click="news = '新闻动态'">新闻动态</el-button>
            <el-button type="primary" @click="news = '行业资讯'">行业资讯</el-button>
            <el-button type="primary" @click="news = '项目新闻'">项目新闻</el-button>
          </div>
        </div>
        <div class="flexcenter">
          <el-row
            style="width: 80%;margin-top: 2rem; display: flex;justify-content: space-between;flex-direction: row; flex-wrap: wrap;flex-shrink:1;"
            :gutter="40">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <img src="../assets/xw.png" style="width:100%;height: 25rem;float: right;"
                :style="screens > 1030 ? '' : 'height: 10rem;'">
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="newsboxs">
              <div v-for="item in arrdata" :key="item.id">
                <div class="fonts" :style="screens > 1030 ? '' : 'font-size: 1rem;'" @click="wz(item.id)">
                  <div class="fontss">{{ item.title }}</div>
                  <div style="width:123px;text-align: right;">{{ item.createTime }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="main_background" :style="screens > 1030 ? '' : 'background: none;'">
      <div class="title" :style="screens > 1030 ? 'color: white;' : ''">核心产品展示</div>
      <div class="flexcenter">
        <div :style="screens > 1030 ? '' : 'width: 100%;height: 15rem;'"
          style="width: 40%;height: 30rem;margin-top: 2rem;">
          <el-carousel :interval="4000" type="card" :height="screens > 1030 ? '30rem' : '15rem'">
            <el-carousel-item>
              <img src="../assets/1.png" style="width: 100%;height: 100%;">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/2.png" style="width: 100%;height: 100%;">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/3.png" style="width: 100%;height: 100%;">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/4.png" style="width: 100%;height: 100%;">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/5.png" style="width: 100%;height: 100%;">
            </el-carousel-item>
            <el-carousel-item>
              <img src="../assets/6.png" style="width: 100%;height: 100%;">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="line flexcenter" style="margin: 1rem;">
      <div style="width: 10%;">
        <div class="line_box">
          <div>
            合作伙伴
          </div>
        </div>
      </div>
    </div>
    <div class="flexcenter">
      <div class="main_bottom">
        <el-row :gutter="20" style="width: 100%;">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="qiyebox">
              <img src="../assets/sy_1.png" style="width: 10rem;height: 10rem">
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="qiyebox">
              <img src="../assets/sy_2.png" style="width: 10rem;height: 10rem">
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="qiyebox">
              <img src="../assets/sy_3.png" style="width: 10rem;height: 10rem">
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="width: 100%;">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="qiyebox">
              <img src="../assets/sy_4.png" style="width: 10rem;height: 10rem">
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="qiyebox">
              <img src="../assets/sy_5.png" style="width: 10rem;height: 10rem">
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="qiyebox">
              <img src="../assets/sy_6.png" style="width: 10rem;height: 10rem">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css';
import { getshouyeimg, getEssayById } from "../API/axios"
export default {
  props: ['screens'],
  name: 'App',
  data() {
    return {
      arrdata: [],
      getobjarr: {},
      luobotuing: [],
      dt: '10rem',
      news: '新闻动态',
    }
  },
  methods: {
    wz(id) {
      this.$router.push({ name: 'interior', query: { id: id } })
    },
    dynamic() {
      this.$router.push('/dynamic')
    },
    xiangqing() {
      this.$router.push('/guanyuwomen')
    }
  },
  created() {
    getshouyeimg().then((res) => {
      this.luobotuing = res.data.data
    })
    getEssayById(this.news).then((res) => {
      this.arrdata = res.data.data
    })
  },
  watch: {
    news: {
      handler(v) {
        getEssayById(this.news).then((res) => {
          this.arrdata = res.data.data
        })
      },
      immediate: true
    },
  },

}
</script>

<style scoped>
.line {
  transform: rotate(-0.1deg);
  border: 1rem solid rgba(87, 104, 235, 1);
  background-color: rgba(87, 104, 235, 1);
  margin: 1rem 0;
}
.main_background {
  background: url(../images/bjblock.jpg) no-repeat;
  padding: 2rem;background-size: 100%;
}
.line_box {
  font-size: 36px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: top;
  background: rgba(6, 28, 150, 1);
  border-radius: 21px;
  padding: 1rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.qiyebox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_bottom {
  width: 60%;
  border-radius: 45px;
  background: rgba(204, 204, 204, 1);
  border: 9px solid rgba(6, 28, 150, 1);
  margin-top: 1rem;
}

.image {
  width: 100%;
  height: 10rem;
}

.words {
  background: rgba(217, 217, 217, 1);
  width: 100%;
  padding: 0 2rem;
}

.title {
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  text-align: center;
}

.fonts {
  font-size: 1.5rem;
  line-height: 3rem;
  color: rgba(0, 0, 0, 1);
  width: 100%;
  display: flex;
}

.fontss {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom {
  width: 100%;
  height: 30rem;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.wordsfonts {
  width: 100%;
  animation: marquee-wrap 4s infinite linear;
}

.wordsfont {
  font-size: 1rem;
  font-weight: 500;
  line-height: 3rem;
  color: rgba(0, 0, 0, 1);
  float: left;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee-content 4s infinite linear;
}

@keyframes marquee-wrap {

  0%,
  30% {
    transform: translateX(0);
  }

  70%,
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-content {

  0%,
  30% {
    transform: translateX(0);
  }

  70%,
  100% {
    transform: translateX(-100%);
  }
}

.newsboxs {
  height: 25rem;
  overflow: auto;
}

.newsboxs::-webkit-scrollbar {
  display: none;
}
.synopsis{
  width: 100vw;
  margin: 30px 20px;
}
.synopsis-title{
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
}
.synopsis-content{
  display: flex;
  justify-content: center;
}
.synopsis img{
  width: 100%;
  height: 450px;
}
.synopsis-text{
  background-color: #CCCCCC;
  width: 950px;
  height: 450px;
  border-radius: 15px;
  font-size: 28px;
  padding: 0 30px;
  font-weight: 540;
  position: relative;
}
.synopsis-text button{
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 130px;
    color: white;
    border: 0px;
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
    background-color: #3E9FFF;
}
</style>